<template>
  <v-card>
    <v-card-title class="text-h5 primary--text">
      {{isNew ? 'Novo Proprietário' : 'Editar Proprietário'}}

      <v-spacer/>

      <label for="status" class="text-body-2 mr-2">{{owner.status ? 'Ativo' : 'Inativo' }}</label>
      <v-switch id="status" v-model="owner.status"/>
    </v-card-title>

    <v-card-text>
      <form class="py-5">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="owner.name"
              color="primary"
              label="Nome"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="owner.contact"
              color="primary"
              label="Contato"
              v-mask="['(##) ####-####', '(##) #####-####']"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="owner.email"
              type="email"
              color="primary"
              label="Email"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="owner.password"
              :type="showPass ? 'text' : 'password'"
              color="primary"
              label="Senha"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :type="showPass ? 'text' : 'password'"
              color="primary"
              label="Senha"
              :rules="[value => value === owner.password || 'Senha não confere']"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="primary"
        text
        @click="$emit('close')"
      >
        Cancelar
      </v-btn>
      <v-btn
        v-if="isNew"
        color="primary"
        @click="create"
      >
        <v-icon small>mdi-check</v-icon>
        Cadastrar
      </v-btn>
      <v-btn
        v-else
        color="primary"
        @click="update"
      >
        <v-icon small>mdi-check</v-icon>
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    isNew: Boolean,
    owner: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showPass: false,
    profiles: [],
  }),
  methods: {
    create() {
      this.$emit('create')
      this.$emit('close')
    },
    update() {
      this.$emit('update')
      this.$emit('close')
    },
  },
}
</script>